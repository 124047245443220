.logo {
  margin: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  margin: 16px;
  color: rgba(255, 255, 255, 0.65);
  white-space: nowrap;
  img {
    max-width: 30px;
  }
}

.toggle-button {
  font-size: 16px;
  width: 64px !important;
  height: 64px;
}

.current-user-wrapper {
  margin: 0 16px 0 auto;
}

.site-layout {
  z-index: 1;
}

/* For small screens */
@media (max-width: 767px) {
  .ant-layout-sider.ant-layout-sider-collapsed {
    flex: 0 0 0px !important;
    max-width: 0px !important;
    min-width: 0px !important;
    width: 0px !important;
  }
}
