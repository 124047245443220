body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-input-number {
  width: 100% !important;
}

#root {
  &,
  > section {
    height: 100%;
    min-height: 100vh;
  }
}

html,
body {
  height: auto !important;
  min-height: 100vh !important;
}

.w-100 {
  width: 100%;
}

.ant-picker-date-panel tbody tr td:last-child {
  background-color: #ffccc799;
}

.ant-picker-date-panel tbody tr td:nth-last-child(2) {
  background-color: #ffe58f99;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: rgb(120, 129, 149);
}

h1 {
  font-size: 18px;
}
